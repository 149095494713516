import * as Sentry from "@sentry/capacitor"
import { createBrowserHistory } from "history"
import { Route } from "react-router-dom"
import { BrowserTracing, withSentryRouting, init as ReactInit } from "@sentry/react"

export const sentryHistory = createBrowserHistory()
export const SentryRoute = withSentryRouting(Route)

export function initSentry(): void {

  const tracePropagationTargets = JSON.parse(process.env.REACT_APP_TRACE_PROPAGATION_TARGETS || '[]')

  Sentry.init(
    {
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          tracePropagationTargets,
        }),
      ],
      tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACESSAMPLERATE || "0.1"),
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      sendClientReports: true,
      sampleRate: Number(process.env.REACT_APP_SENTRY_SAMPLERATE),
      attachStacktrace: true,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      dist: process.env.REACT_APP_SENTRY_DIST,
      enableTracing: true,
    },
    ReactInit
  )
}
