import {IonButton, IonIcon, useIonViewWillEnter, withIonLifeCycle} from "@ionic/react";
import React, {useEffect, useState} from "react";
import "./NewRequestValidation.scss";
import {chevronBackOutline} from "ionicons/icons";
import {Helmet} from "react-helmet-async";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import { useConfig } from "../../hooks/config"
import AuthLayout from "../../layouts/AuthLayout";
import Footer from "../../components/Footer";
import type {RootState} from "../../stores/reducers";

function NewRequestValidation() {
    const router = useHistory()
    const location = useLocation();
    const [response, setResponse] = useState<any>([])
    const [messagePrincipal, setMessagePrincipal] = useState<string>("");
    const [informationSupp, setInformationSupp] = useState<string>("");
    const { data: config } = useConfig()
    const dark = useSelector((state: RootState) => state.theme.dark)

    useIonViewWillEnter(() => {
        // Recharge les données à chaque entrée sur la vue
        const storedData = sessionStorage.getItem("requestValidated");
        if (storedData) {
            setResponse(JSON.parse(storedData));
        }
    });

    useEffect(() => {
        const information = sessionStorage.getItem('requestValidated')
        if (information) {
            setResponse(JSON.parse(information))
        } else {
            setResponse([]);
        }
    }, []);

    useEffect(() => {
        if (response.messagePrincipal !== "") {
            setMessagePrincipal(response.messagePrincipal);
        } else {
            setMessagePrincipal("");
        }
    }, [response]);

    useEffect(() => {
        if (response.informationSupplementaire !== "") {
            setInformationSupp(response.informationSupplementaire);
        } else {
            setInformationSupp("");
        }
    }, [response]);

    const returnToDashboard = () => {
        router.push('/dashboard')
    }

    const returnToDemandes = () => {
        router.push('/demandes')
    }

    return (
        <>
            <Helmet>
                <title>Demande acceptée - Espace Locataire - Vilogia</title>
                <meta name="description" content="Validation de votre demande auprès de Vilogia"/>
                <link rel="canonical" href={location?.pathname}/>
            </Helmet>
            <AuthLayout>
                <div>
                    <section className="container-section-width--padded">
                        <IonButton className="button-return-dashboard" onClick={returnToDashboard} shape="round"
                                   fill="outline" style={{textTransform: 'none', color: (dark ? undefined : config?.primaryColor), "--border-color": (dark ? undefined : config?.primaryColor)}}>
                                <IonIcon icon={chevronBackOutline} slot="start"/>
                                Accueil
                        </IonButton>
                    </section>
                    <div className="container-content">
                        <div className="title-thanks">Merci !</div>
                        <img alt="horloge-logo" src="/assets/svgs/horloge-bleu.svg" width="350px" height="350px"/>
                        <div className="message-text first-message"
                             dangerouslySetInnerHTML={{__html: messagePrincipal}}/>
                        <IonButton onClick={returnToDemandes} shape="round" style={{"--background": (dark ? undefined : config?.primaryColor)}}>
                            Suivre ma demande
                        </IonButton>
                        {informationSupp !== "" &&
                          <div className="container-content-information">
                            <img alt="ampoule" src="/assets/icon/ampoule.png" width="64px" height="64px"/>
                            <div className="message-text info-supp-message"
                                 dangerouslySetInnerHTML={{__html: informationSupp}}/>
                          </div>
                        }
                    </div>
                </div>
                <Footer />
            </AuthLayout>
        </>
    );
}

export default withIonLifeCycle(NewRequestValidation);