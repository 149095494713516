import {IonIcon, IonSpinner, useIonViewWillEnter} from "@ionic/react"
import React, {useEffect, useRef, useState} from "react"
import moment from "moment"
import * as Yup from "yup"
import {useFormik} from "formik"
import {useHistory} from "react-router"
import {createPayment} from "api/payzen"
import {useDispatch} from "react-redux"

import "./RentPayment.scss"
import Skeleton from "react-loading-skeleton"
import Button from "./Button"
// import Input from "./Input"
import TooltipIcon from "./TooltipIcon"

import calendar from "../theme/images/calendar.png"
import check from "../theme/images/checkmark-outline.svg"
import card_icon from "../theme/images/card_icon.svg"
import {toggleErrorMessageAction} from "../stores/actions/toastMessageActions"
import {type RentLastPayment, type RentSummary} from "../types/common"
import "react-loading-skeleton/dist/skeleton.css"
import "moment/min/locales"
import {useMediaExist} from "../utils/hooks"
import {useBillingSummary} from "../hooks/user"
import {getSepaFile} from "../utils/functions"
import Input from "./Input";

interface RentSummaryProps {
  summary?: RentSummary
  last_payment?: RentLastPayment
  isLoading: boolean
}

const defaultProps = {
  last_payment: undefined,
  summary: undefined,
}

function RentPayment({ summary, last_payment, isLoading }: RentSummaryProps) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [clickState, setClickState] = useState<"initial" | "loading" | "success" | "error">("initial")
  const inputRef = useRef<HTMLIonInputElement>(null);

  useEffect(() => {
    setClickState("initial")

    return () => {
      setClickState("initial")
    }
  }, [])

  const validationSchema = Yup.object().shape({
    rentAmount: Yup.string()
      .matches(/^(\d+(?:[.,]\d{1,2})?)$/, "Veuillez indiquer un montant valide.")
      .required("Veuillez indiquer un montant valide"),
  })

  const inputPayment = summary?.solde && summary?.solde > 0 ? summary?.solde.toFixed(2) : "0"

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      rentAmount: inputPayment,
    },
    enableReinitialize: true,
    validationSchema,
    // Get tokenForm from payzen + redirect to payzen embedded form
    onSubmit: async (values) => {
      const formatedPayment = values.rentAmount.includes(",") ? parseFloat(values.rentAmount.replace(",", ".")) : parseFloat(values.rentAmount)
      await createPayment(formatedPayment)
        .then((response) => {
          const data = JSON.parse(response.data)
          const { formToken } = data.answer
          history.push("/payzen-form", { formToken, rentAmount: formatedPayment})
        })
        .catch(() => {
          dispatch<any>(toggleErrorMessageAction())
        })
    },
  });

  useIonViewWillEnter(() => {
    formik.resetForm();
  });

  const { data: billingSummary } = useBillingSummary()

  const mediaExist: boolean = useMediaExist(billingSummary?.sepa)

  useEffect(() => {
    if (undefined !== billingSummary?.sepa && billingSummary?.sepa !== null) {
      fetch(`${process.env.REACT_APP_BACKEND}${billingSummary?.sepa}`)
        // .then((response) => {
        //   setSize(response.headers.get("content-length"))
        // })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [billingSummary?.sepa])


  useEffect(() => {
    // Ajouter un gestionnaire d'événement `blur` à l'élément natif
    const handleBlur = (event: FocusEvent) => {
      const inputElement = event.target as HTMLInputElement;

      // Logique personnalisée
      if (!formik.values.rentAmount) {
        formik.setFieldValue("rentAmount", inputPayment);
      }

      // Appeler la gestion Formik
      formik.handleBlur({
        target: inputElement,
        currentTarget: inputElement,
      } as React.FocusEvent<HTMLInputElement>);
    };

    // Attacher le gestionnaire au composant Ionic
    const {current} = inputRef;
    if (current) {
      current.addEventListener('blur', handleBlur);
    }

    // Nettoyer l'événement lors du démontage
    return () => {
      if (current) {
        current.removeEventListener('blur', handleBlur);
      }
    };
  }, [formik]);

  const handleInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value || '';

    // Appliquer un filtrage : conserver uniquement chiffres, "." et ","
    const filteredValue = inputValue.replace(/[^0-9.,]/g, '');

    // Mettre à jour la valeur dans Formik
    formik.setFieldValue('rentAmount', filteredValue);
  };

  return (
    <div className="rent_page card">
      <div className="rent_info-content">
        <div className="rent_total-container">
          <div className="rent__total">
            <h2>Montant total à payer*</h2>
            <TooltipIcon help="Montant total dû, hors règlements en cours. Certaines opérations peuvent prendre plusieurs jours." />
          </div>
          <span className="rent__price">
            {summary?.solde ? parseFloat(summary?.solde.toFixed(2)).toLocaleString() : "0"}€
          </span>
        </div>
        {last_payment?.amount ? (
          <div className="rent__info">
            <IonIcon icon={check} aria-hidden="true" />
            <p>
              Votre dernier paiement a été effectué le{" "}
              <span className="strong">
                {moment(last_payment?.date)
                  .locale("fr")
                  .format("LL")}
              </span>
              , pour un montant de{" "}
              <span className="strong">
                {last_payment?.amount ? Math.abs(parseFloat(last_payment?.amount.toFixed(2))).toLocaleString() : "0"}€
              </span>
            </p>
          </div>
        ) : isLoading ? (
          <Skeleton count={2} />
        ) : null}
      </div>

      <div className="rent_button-content">
        <div className="automatic-payment_button-container">
          <Button
            disabled={!mediaExist || !billingSummary?.sepa}
            onClick={() => {
              if (undefined !== billingSummary) {
                getSepaFile(billingSummary, setClickState)
              }
            }}
            className="automatic-payment_button dark-green"
          >
            {clickState === "loading" ? (
              <IonSpinner />
            ) : (
              <>
                <img src={calendar} alt="" className="calendar_icon" aria-hidden="true" />
                <div>
                  <span className="auto-payment_span">TÉLÉCHARGER UN MANDAT SEPA</span>
                  <br />
                  PDF
                  <strong> {`${Math.round(billingSummary?.sepaSize || 0)} ko` || "Poids non défini"}</strong>
                </div>
              </>
            )}
          </Button>
          <TooltipIcon help="Pour mettre en place le prélèvement automatique : imprimez le mandat SEPA, remplissez et scannez le document, puis renvoyez-le accompagné d’un RIB via la messagerie." />
        </div>
        <form onSubmit={formik.handleSubmit} className="payment_form">
          {billingSummary?.societyPaymentMode === "EMBED_FORM" && (
            <div>
              <div className="payment">
                <p className="payment_text">
                  Je règle mon loyer,
                  <br />
                  en partie ou totalement
                </p>
                <div className="input_rent-amount">
                  <Input
                      id="rentAmount"
                      sr_only="Je règle mon loyer,
                en partie ou totalement"
                      name="rentAmount"
                      inputmode="decimal"
                      className="input_payment"
                      placeholder="Veuillez entrer un montant pour régler votre loyer"
                      formik={formik}
                      type="text"
                      title="Je règle mon loyer,
                en partie ou totalement"
                      displayErrorOnTop
                      innerRef={inputRef}
                      onKeyUp={handleInput}
                  />
                </div>
              </div>
              <Button
                  className="rent_pay-button"
                  type="submit"
                  // On remplace les , par des . car parseFloat ne gère pas les chiffres écrit en ,
                disabled={!formik.isValid || formik.isSubmitting || parseFloat(formik.values.rentAmount.replace(',', '.')) <= 0}
              >
                <IonIcon icon={card_icon} className="card_icon" aria-hidden="true" />
                Je paye par carte bancaire
              </Button>
            </div>
          )}
        </form>
        {billingSummary?.societyPaymentMode === "REDIRECT" && (
          <a href={billingSummary?.paymentLink}>
            <Button className="rent_pay-button" type="submit">
              <IonIcon icon={card_icon} className="card_icon" aria-hidden="true" />
              Je paye par carte bancaire
            </Button>
          </a>
        )}
        {billingSummary?.societyPaymentMode === "NONE" && (
          <div>
            <p className="rent_pay_unauthorized">*La société n&apos;autorise pas le paiement en ligne.</p>
          </div>
        )}
      </div>
    </div>
  )
}

RentPayment.defaultProps = defaultProps

export default RentPayment
