import { useRef } from "react"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import * as actions from "../stores/actions/promptActions"
import "./Preference.scss"
import { Consent } from "types/common"
import { patchProfileConsents } from "api/profile"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useDispatch } from "react-redux"
import { toggleErrorMessageAction } from "stores/actions/toastMessageActions"
import { useProfileConsent } from "hooks/user"

// interface PreferenceType {
// }

function Preference() {
  const switchRef: any = useRef(new WeakMap())
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { data: consents } = useProfileConsent()

  const showErrorNotification = (message: string) => dispatch<any>(toggleErrorMessageAction(message))

  const onMutate = async (variables: Consent) => {
    // Cancel any outgoing refetches for that `queryKey`
    await queryClient.cancelQueries({ queryKey: ["consent"] });
    // Snapshot the previous value
    const prevData = consents?.find((c) => c.id === variables.id);
    // Generate new data based on `prevData` and `variables`
    const newData = { ...prevData, consentGiven: variables.consentGiven };
    // Optimistically update to the new value
    queryClient.setQueryData(["consent"], newData);
    // Return a context with the previous and new data
    return { prevData, newData };
  }

  const onError = (error: any, variables: Consent, context: any) => {
    console.debug("🚀 ~ Preference update > onError ~ variables:", variables)
    console.debug("🚀 ~ Preference update > onError ~ context:", context)
    console.error("🚀 ~ Preference update > onError ~ error:", error)

    // Rollback to the previous status in case of an error
    queryClient.setQueryData(["consent"], context.prevData);

    // Display the error message
    showErrorNotification("Une erreur est survenue lors de la mise à jour de vos préférences");
  }

  const onSettled = () => {
    // Invalidate the query to refetch the latest data
    dispatch(actions.setCheckBlocking(false))
    queryClient.invalidateQueries({ queryKey: ["consent"] })
  }

  const { mutate } = useMutation({
    mutationFn: patchProfileConsents,
    onMutate,
    onError,
    onSettled,
  });

  const updateFieldChanged = (consent: Consent) => {
    mutate({ ...consent, consentGiven: !consent.consentGiven })
  }

  return (
    <div className="container_preference" title="Mes préférences">
      <div className="container_preference">
        <h2 className="preference_title">Mes préférences</h2>
        <div className="myprofile_table-wrapper card">
          {" "}
          <fieldset className="preference_fieldset">
            <legend className="sr-only">Consentement et gestion des préférences</legend>
            <table role="presentation" className="myprofile_table">
              <tbody>
                {consents?.map((consent: Consent, i: number) => (
                  <tr key={consent.consentType}>
                    <td onClick={() => switchRef.current.get(consent).click()} tabIndex={0}>
                      {consent?.consentTypeLabel || <Skeleton />}
                    </td>
                    <td>
                      <label className="switch" htmlFor={`consents${i}`} tabIndex={0}>
                        <div className="sr-only">{consent.consentTypeLabel}</div>

                        <input
                          ref={(el) => switchRef.current.set(consent, el)}
                          id={`consents${i}`}
                          name="consents"
                          type="checkbox"
                          checked={consent.consentGiven}
                          onChange={() => updateFieldChanged(consent)}
                        />
                        <div className="slider" />
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>{" "}
          </fieldset>
        </div>
      </div>
    </div>
  )
}

export default Preference
