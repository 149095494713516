import { QueryClient } from "@tanstack/react-query"
import OneSignal from "onesignal-cordova-plugin"
import { setAuthData } from "../stores/actions/authActions"
import store from "../stores/store"
import { type Auth } from "../types/common"
import { API } from "./axioshelper"
import { setOneSignalUuid } from "./account"
import {isPlatform} from "@ionic/react";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET

export async function login(
  username: string,
  password: string,
  remember_me: boolean,
  platforms: string[] | undefined,
  appVersion: string,
  appBuild: string,
  queryClient: QueryClient
) {
  return await new Promise((resolve, reject) => {
    API.request<Auth>({
      method: "post",
      url: "oauth/v2/token",
      data: {
        grant_type: "password",
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        username,
        password,
        platforms,
        appVersion,
        appBuild,
      },
    })
      .then(async (response) => {
        // Temps d'expiration
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { access_token, refresh_token } = response.data
        const expireDays = 30
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const expire_date = Date.now() + expireDays * 1000 * 60 * 60 * 24

        // stockage user dans redux
        store.dispatch(
          setAuthData({
            expire_date,
            access_token,
            refresh_token,
            remember_me,
          })
        )

      if (isPlatform("capacitor")) {
          OneSignal.getDeviceState(async (deviceState) => {
              await setOneSignalUuid(deviceState.userId)
          })
      }

        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => queryClient.removeQueries())
  })
}

export default login
