import { IonIcon } from "@ionic/react"
import React, { useState } from "react"
import type { TextFieldTypes } from "@ionic/core/dist/types/interface"
import eyeOffOutline from "../theme/images/icon_eye-close.svg"
import eye from "../theme/images/icon_eye-open.svg"
import wrong from "../theme/images/wrong.svg"
import "./Input.scss"

import check from "../theme/images/checkmark-outline.svg"
import modify from "../theme/images/icon_modify.svg"
import TooltipIcon from "./TooltipIcon"
import { type FormikType } from "../types/utils"
import calendarOutline from "../theme/images/calendar-outline.svg"
import { useWindowSize } from "../utils/hooks"
import PasswordSecurityHelper from "./PasswordSecurityHelper"

interface InputProps {
    id: string
    name: string
    className?: string
    placeholder?: string
    type?: TextFieldTypes
    value?: string | number | null
    inputmode?: "email" | "search" | "tel" | "text" | "url" | "none" | "numeric" | "decimal"
    icon?: any
    help?: string
    error?: string
    valid?: boolean
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    formik?: FormikType<any>
    modifier?: boolean
    innerRef?: any
    sr_only?: string
    title?: string
    ariaLabelledby?: string
    ariaDescId?: string
    ariaRequired?: boolean
    autoComplete?: string
    trim?: boolean
    passwordSecurity?: boolean
    handleModify?: () => void
    displayErrorOnTop?: boolean
    onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>
}

const defaultProps = {
    className: "",
    placeholder: "",
    type: undefined,
    value: null,
    inputmode: "text",
    icon: undefined,
    help: "",
    error: undefined,
    valid: undefined,
    onChange: undefined,
    formik: undefined,
    modifier: undefined,
    innerRef: undefined,
    sr_only: "",
    title: "",
    ariaLabelledby: "",
    ariaDescId: "",
    ariaRequired: false,
    autoComplete: "",
    trim: false,
    passwordSecurity: false,
    handleModify: undefined,
    displayErrorOnTop: false,
    onKeyUp: undefined
}

function Input({
    id,
    name,
    placeholder,
    className = "",
    inputmode,
    type = "text",
    icon,
    value,
    help,
    error,
    valid,
    onChange,
    formik,
    modifier,
    innerRef,
    sr_only,
    title,
    ariaLabelledby,
    ariaDescId,
    ariaRequired,
    autoComplete,
    trim,
    passwordSecurity,
    handleModify: handleModifyProps,
    displayErrorOnTop,
    onKeyUp
}: InputProps) {
    const size = useWindowSize()
    const [hide, sethide] = useState(true)
    const [disabled, setdisabled] = useState(true)
    const computedClassName = `input-wrapper ${className}`

    const toggleHide = () => {
        sethide(!hide)
    }

    const openCalendar = () => {
        innerRef.current.click()
    }

    const handleModify = () => {
        setdisabled(false)
        innerRef.current.click()
        if (handleModifyProps) {
            handleModifyProps()
        }
    }


    // If formik is defined
    // @ts-ignore
    const computedError: undefined | string =
        undefined !== formik
            ? // Check if input is "touched"
            formik.touched[name] && typeof formik.errors[name] === "string"
                ? // if yes return its error
                formik.errors[name]
                // if not return error props
                : error
            // Return error props if formik is undefined
            : error


    // If formik is defined
    const computedValid = formik
        ? // Check if input is "touched" and there is no error
        formik.touched[name] && !formik.errors[name]
        : // Return valid props if formik is undefined
        valid

    if (trim) {
        if (undefined !== formik) {
            // eslint-disable-next-line no-param-reassign
            formik.values[name] = formik.values[name].trim()
        }
    }

    return (
        <>
            {computedError && displayErrorOnTop === true && (
                <p className="input-error" id={ariaDescId}>
                    {computedError}
                </p>
            )}
            <div className={computedClassName}>
                <label className="input-row" htmlFor={id}>
                    <div className="side-item side-item_left">
                        <IonIcon icon={icon} className="input_icon" aria-hidden="true" />
                    </div>
                    <input
                        aria-label={sr_only}
                        title={title}
                        className="input focusable"
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        type={type === "password" && !hide ? "text" : type}
                        inputMode={inputmode}
                        value={formik ? formik.values[name] : value}
                        onChange={formik ? formik.handleChange : onChange}
                        // onBlur={() => setdisabled(true)}
                        onBlur={
                            formik
                                ? formik.handleBlur
                                : () => {
                                    setdisabled(true)
                                }
                        }
                        ref={innerRef}
                        disabled={modifier && disabled}
                        aria-labelledby={ariaLabelledby}
                        aria-describedby={ariaDescId}
                        aria-required={ariaRequired}
                        autoComplete={autoComplete ?? "on"}
                        onKeyUp={onKeyUp ?? undefined}
                    />
                </label>
                {computedValid && (
                    <div className="side side--no-border">
                        <div className="side-item side-item_test">
                            <IonIcon icon={check} aria-hidden="true" />
                        </div>
                    </div>
                )}

                {computedError && (
                    <div className="side side--no-border">
                        <div className="side-item side-item_wrong">
                            <IonIcon icon={wrong} aria-hidden="true" />
                        </div>
                    </div>
                )}

                <div className="side">
                    {type === "password" && !modifier && (
                        <button
                            className="side-item"
                            onClick={toggleHide}
                            type="button"
                            title={hide ? "Afficher le mot de passe" : "Cacher le mot de passe"}
                        >
                            <span className="sr-only">{hide ? "Afficher le mot de passe" : "Cacher le mot de passe"}</span>
                            <IonIcon icon={hide ? eye : eyeOffOutline} aria-hidden="true" />
                        </button>
                    )}

                    {type === "date" && (
                        <label
                            className="side-item side-item--no-focus"
                            htmlFor={id}
                            tabIndex={-1}
                            title={title}
                            onClick={openCalendar}
                        >
                            <IonIcon icon={calendarOutline} title={title} aria-hidden="true" />
                            <span className="sr-only">Ouvrir le calendrier</span>
                        </label>
                    )}

                    {modifier && (
                        <button type="button" onClick={handleModify} className="icon_modifier" onFocus={handleModifyProps}>
                            <label htmlFor={id}>
                                <span className="sr-only">{sr_only}</span>
                                {size.width > 767 && <p className="modifier_p">Modifier</p>}
                                <IonIcon icon={modify} aria-hidden="true" />
                            </label>
                        </button>
                    )}

                    {help && <TooltipIcon className="side-item" help={help} />}
                </div>
            </div>
            {computedError && displayErrorOnTop === false && (
                <p className="input-error" id={ariaDescId}>
                    {computedError}
                </p>
            )}
            {passwordSecurity && !disabled && undefined !== formik && (
                <>
                    <PasswordSecurityHelper password={formik.values.password} />
                    <div>
                        <p className="confirm input-label_profile">
                            Confirmez votre mot de passe
                            <span className="my-profil_subtitle"> Obligatoire</span>
                        </p>
                        <Input
                            className="profil__input--password"
                            id="passwordConfirm"
                            sr_only="Confirmer votre mot de passe"
                            name="passwordConfirm"
                            type="password"
                            formik={formik}
                            title="confirmation de mot de passe"
                            ariaDescId="errorPasswordConfirm"
                            ariaRequired
                        />
                    </div>
                </>
            )}
        </>
    )
}

Input.defaultProps = defaultProps

export default Input
